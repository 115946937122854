import React, { useCallback, useEffect, useState } from "react";
import style from "./stylesheets/DiscoveryPage.module.scss";
import ChameleonLogo from "../resources/chameleon.png";
import { Link, useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getUID } from "../helpers/localStorage";
import confetti from "canvas-confetti";
import Button from "./Button";
import Input from "./Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import MailingListBox from "./MailingListBox";

const allowedCharsRegex = /^[ a-z0-9_-]+$/i;

const DiscoveryPage = () => {
    const functions = getFunctions();
    const [error, setError] = useState("");
    const [hintData, setHintData] = useState(null);
    const [assignedNameInput, setAssignedNameInput] = useState("");
    const [discovererNameInput, setDiscovererNameInput] = useState("");
    const [venmoInput, setVenmoInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const { token } = useParams();
    const uid = getUID();

    useEffect(() => {
        (async () => {
            let result;
            try {
                const func = httpsCallable(functions, "discoverHint");
                result = await func({ token, uid });
            } catch(e) {
                if(e.code === "functions/not-found") setError("Cheaters never win.");
                else if(e.code === "functions/failed-precondition") setError("This chameleon hasn't released yet! Come back later.");
                else setError("Unexpected error");
                return;
            }
            if(!result.data) {
                setError("Failed to fetch hint");
                console.log(result);
                return;
            }
            setHintData(result.data);
            confetti({
                particleCount: 200,
                spread: 200,
                scalar: 1.5,
                ticks: 500,
                disableForReducedMotion: true,
            });
        })();
    }, [functions, token, uid]);

    const submit = useCallback(async () => {
        setError("");

        if(!assignedNameInput) {
            setError("Please name your chameleon.");
            return;
        }
        if(!discovererNameInput) {
            setError("Please enter your discoverer's name.");
            return;
        }
        if(assignedNameInput.length > 30) {
            setError("Chameleon name must be 30 characters or less.");
            return;
        }
        if(discovererNameInput.length > 30) {
            setError("Discoverer's name must be 30 characters or less.");
            return;
        }
        if(!allowedCharsRegex.test(assignedNameInput)) {
            setError("Chameleon name must only contain English letters and numbers.");
            return;
        }
        if(!allowedCharsRegex.test(discovererNameInput)) {
            setError("Discoverer's name must only contain English letters and numbers.");
            return;
        }
        setLoading(true);
        let result;
            try {
                const func = httpsCallable(functions, "firstToDiscover");
                result = await func({
                    token,
                    uid, 
                    discovererDisplayName: discovererNameInput,
                    assignedName: assignedNameInput,
                    discovererVenmo: venmoInput,
                });
            } catch(e) {
                if(e.code === "functions/not-found") setError("Cheaters never win.");
                else if(e.code === "functions/already-exists") setError("Someone beat you to it!");
                else setError("Unexpected error");
                setLoading(false);
                return;
            }
            if(!result.data || result.data.congrats !== true) {
                setError("Failed to update data. Try again");
                console.log(result);
                setLoading(false);
                return;
            }
            confetti({
                particleCount: 200,
                spread: 200,
                scalar: 1.5,
                ticks: 500,
                disableForReducedMotion: true,
            });
            setLoading(false);
            setFormSubmitted(true);
    }, [assignedNameInput, discovererNameInput, functions, token, uid, venmoInput]);

    if(!hintData) {
        return (
            <div id={style.discovery_page}>
                <Link to="/">
                    <img src={ChameleonLogo} alt="Chameleon Logo" className="logo" />
                </Link>
                <h1>You found me!</h1>
                <h2>Loading your discovery...</h2>
                {error && <p id={style.error}>{error}</p>}
            </div>
        );
    }

    const discoverersBox = hintData.discovered && (
        <div className={style.box}>
            <p>But it looks like someone's beat you to it...</p>
            <h2>#{hintData.id}: {hintData.assignedName}</h2>
            <h3>Discovered by {hintData.discovererDisplayName}</h3>
            <p>Please don't take me home! Leave me for others to find.</p>
        </div>
    );

    let submitInfoBox;
    if(hintData.discovered) submitInfoBox = null;
    else if(formSubmitted) submitInfoBox = (
        <div className={style.box}>
            <p>Congratulations!</p>
            <p>Please don't take me home! Leave me for others to find.</p>
        </div>
    );
    else if(loading) submitInfoBox = (
        <div className={style.box}>
            <p>Submitting...</p>
        </div>
    );
    else submitInfoBox = (
        <div className={style.box} id={style.submit_info_box}>
            <p>You were the first to discover this chameleon! You get to give it a name.</p>
            <Input
                placeholder="Chameleon name"
                value={assignedNameInput}
                onChange={(e) => setAssignedNameInput(e.target.value)}
            />
            <Input
                placeholder="Discoverer's name"
                value={discovererNameInput}
                onChange={(e) => setDiscovererNameInput(e.target.value)}
            />
            <Input
                placeholder="Venmo username (optional)"
                value={venmoInput}
                onChange={(e) => setVenmoInput(e.target.value)}
            />
            <Button text="Submit" onClick={submit} />
        </div>
    );

    const findMoreButton = (hintData.discovered || formSubmitted) && (
        <Link to="/">
            <Button text={
                <span>
                    Find more chameleons&nbsp;
                    <FontAwesomeIcon icon={faArrowRight} />
                </span>
            } />
        </Link>
    );

    return (
        <div id={style.discovery_page}>
            <Link to="/">
                <img src={ChameleonLogo} alt="Chameleon Logo" className="logo" />
            </Link>
            <h1>You found me!</h1>
            <h2>Chameleon #{hintData.id}</h2>
            {error && <p id={style.error}>{error}</p>}
            {discoverersBox}
            {submitInfoBox}
            { (formSubmitted || hintData.discovered) && <MailingListBox /> }
            {findMoreButton}
        </div>
    );
};

export default DiscoveryPage;
