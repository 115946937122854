import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import { initializeApp } from "firebase/app";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

initializeApp({
	apiKey: "AIzaSyDKwYubRVLuc8_euqjdpkhr-RntKmcDOis",
	authDomain: "yale-scav.firebaseapp.com",
	projectId: "yale-scav",
	storageBucket: "yale-scav.appspot.com",
	messagingSenderId: "7811932538",
	appId: "1:7811932538:web:900f2d2ac862e36742f6c3"
});

if(window.location.hostname === "localhost") {
	const functions = getFunctions();
	connectFunctionsEmulator(functions, "localhost", 5001);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
