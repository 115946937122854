import React from "react";
import style from "./stylesheets/Input.module.scss"

const Input = ({ placeholder, onChange, value, type }) => {
    if(type === "textarea") return (
        <textarea className={style.input} placeholder={placeholder} onChange={onChange} value={value} />
    );
    return (
        <input type={type || "text"} placeholder={placeholder} onChange={onChange} className={style.input} value={value} />
    );
};

export default Input;
