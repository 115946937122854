import React, { useEffect, useState } from "react";
import style from "./stylesheets/ViewHintPage.module.scss";
import ChameleonLogo from "../resources/chameleon.png";
import Hint from "./Hint";
import { Link, useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { fetchUserData } from "../helpers/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faFlag } from "@fortawesome/free-solid-svg-icons";

const ViewHintPage = () => {
    const functions = getFunctions();
    const { id } = useParams();
    const [ hintData, setHintData ] = useState(null);
    const [ userData, setUserData ] = useState(null);
    const [error, setError] = useState("");
    useEffect(() => {
        (async () => {
            let result;
            try {
                const func = httpsCallable(functions, "getOneHint");
                result = await func({ id });
            } catch(e) {
                console.error(e);
                if(e.code === "functions/not-found") setError("Hint not found");
                else setError("Unexpected error")
                return;
            }
            if(!result.data) {
                setError("Failed to fetch hints");
                console.log(result);
                return;
            }
            setHintData(result.data);
        })();
    }, [functions, id]);

    useEffect(() => {
        (async () => {
            try {
                const data = await fetchUserData(functions);
                setUserData(data);
            } catch(e) {
                console.error(e);
                setError("Failed to fetch user data");
                return;
            }
        })();
    }, [functions]);

    if(!hintData) return (
        <div id={style.view_hint_page}>
            <Link to="/">
                <img src={ChameleonLogo} alt="Chameleon Logo" className="logo" />
            </Link>
            {error && <p id={style.error}>{error}</p>}
            <h2>Loading hint...</h2>
        </div>
    );

    const foundByUser = userData && userData.foundHints.includes(id);
    const isReleased = hintData.releaseTime <= Date.now();

    const headerTitle = hintData.discovered ? hintData.assignedName : "???";
    
    let headerSubtitle;
    if(!isReleased) headerSubtitle = "???";
    else if(foundByUser && hintData.discovered) headerSubtitle = `Found by you • Discovered by ${hintData.discovererDisplayName}`;
    else if(foundByUser && !hintData.discovered) headerSubtitle = "Found by you";
    else if(!foundByUser && hintData.discovered) headerSubtitle = `Not found by you • Discovered by ${hintData.discovererDisplayName}`;
    else if(!foundByUser && !hintData.discovered) headerSubtitle = hintData.reward === 0 ? "Undiscovered" : `Undiscovered • Reward: $${hintData.reward}`;
    
    const reportMissingMailto = `mailto:chameleons@yalescav.com?subject=Missing chameleon ${hintData.id}&body=Chameleon%20${hintData.id}%20has%20gone%20missing.%0A%0APlease%20describe%20the%20location%20you%20checked%3A%0A%0APlease%20attach%20a%20picture%20of%20the%20hiding%20spot%20where%20you%20think%20the%20chameleon%20should%20be.`;

    return (
        <div id={style.view_hint_page}>
            <Link to="/">
                <img src={ChameleonLogo} alt="Chameleon Logo" className="logo" />
            </Link>
            {error && <p id={style.error}>{error}</p>}
            <h1>{headerTitle}</h1>
            <h2>{headerSubtitle}</h2>
            {
                isReleased ? (
                    <Hint hintData={hintData} />
                ) : (
                    <p>Chameleon #{hintData.id} hasn't been released yet.</p>
                )
            }

            <p>
                <a className={style.report_missing} href={reportMissingMailto}>
                    <FontAwesomeIcon icon={faFlag} />
                    Report as missing
                </a>
            </p>
        </div>
    )
};

export default ViewHintPage;
