import React from "react";
import style from "./stylesheets/MailingListBox.module.scss";
import Input from "./Input";
import Button from "./Button";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { hasSubscribedToMailingList, setSubscribedToMailingList } from "../helpers/localStorage";

const MailingListBox = () => {
    const functions = getFunctions();
    const [email, setEmail] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState("");

    const submit = async () => {
        if(!email) {
            setError("Please enter your email address");
            return;
        }
        setLoading(true);
        try {
            const func = httpsCallable(functions, "joinMailingList");
            await func({ email });
        } catch(e) {
            setError("Failed to subscribe. Is your email address valid?");
            setLoading(false);
            return;
        }
        setLoading(false);
        setSuccess(true);
        setSubscribedToMailingList();
    };

    if(loading) {
        return (
            <div className={style.mailing_list_box}>
                <p>Loading...</p>
            </div>
        )
    }

    if(success) {
        return (
            <div className={style.mailing_list_box}>
                <p>You're subscribed!</p>
            </div>
        );
    }

    if(hasSubscribedToMailingList()) return null;

    return (
        <div className={style.mailing_list_box}>
            <h3>Get notified of new drops</h3>
            <Input
                placeholder="Email"
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
            />
            <Button secondary text={(
                <>
                    Subscribe
                    <FontAwesomeIcon icon={faArrowRight} />
                </>
            )} onClick={submit} />
            {error && <p id={style.error}>{error}</p>}
        </div>
    )
};

export default MailingListBox;
