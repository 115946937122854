import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ROUTES from "../helpers/routes";
import { getUID } from "../helpers/localStorage";

const router = createBrowserRouter(ROUTES);

const App = () => {
	return (
		<>
			<RouterProvider router={router} />
			<p className="footer">Made by EY'27 • chameleons@yalescav.com • UID {getUID()}</p>
		</>
	);
}

export default App;
