import React from "react";
import style from "./stylesheets/Button.module.scss";

const Button = ({ text, onClick, secondary }) => {
    return (
        <button onClick={onClick} className={`${style.button} ${secondary && style.secondary}`}>
            {text}
        </button>
    );
};

export default Button;
