import React from "react";
import style from "./stylesheets/Hint.module.scss";

const Hint = ({ hintData }) => {
    if(!hintData) return null;
    
    return (
        <div className={style.hint}>
            <h1>Chameleon #{hintData.id}</h1>
            <p>{hintData.hint}</p>
            {
                hintData.image && (
                    <img src={hintData.image} alt="Visual clue" />
                )
            }
        </div>
    )
};

export default Hint;
