import React, { useCallback, useEffect, useMemo, useState } from "react";
import style from "./stylesheets/HomePage.module.scss";
import ChameleonLogo from "../resources/chameleon.png";
import Hint from "./Hint";
import HintList from "./HintList";
import { getFunctions, httpsCallable } from "firebase/functions";
import moment from "moment";
import { fetchUserData } from "../helpers/data";
import MailingListBox from "./MailingListBox";

const HomePage = () => {
    const functions = getFunctions();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hints, setHints] = useState([]);
    const [error, setError] = useState("");
    const [currentTime, setCurrentTime] = useState(Date.now());

    const unreleasedHints = useMemo(() =>
        hints.filter(hint => hint.releaseTime > currentTime),
        [hints, currentTime]
    );
    const releasedHints = useMemo(() =>
        hints.filter(hint => hint.releaseTime <= currentTime),
        [hints, currentTime]
    );
    const latestReleasedHint = useMemo(() => {
        const notFoundHints = releasedHints.filter(i => !userData || !userData.foundHints.includes(i.id));
        if(notFoundHints.length === 0) return null;
        return notFoundHints.reduce((a, b) => {
            if(a.releaseTime === b.releaseTime) return a.createdAt < b.createdAt ? a : b;
            return a.releaseTime > b.releaseTime ? a : b;
        });
    }, [releasedHints, userData]);
    const nextUnreleasedHint = useMemo(() =>
        unreleasedHints.length === 0 ? null :
        unreleasedHints.reduce((a, b) => a.releaseTime < b.releaseTime ? a : b),
        [unreleasedHints]
    );

    const fetchHints = useCallback(async () => {
        let result;
        try {
            const func = httpsCallable(functions, "getAllHints");
            result = await func();
        } catch(e) {
            console.error(e);
            setError("Unexpected error")
            return;
        }
        if(!result.data || !result.data.hints) {
            setError("Failed to fetch hints");
            console.log(result);
            return;
        }
        setLoading(false);
        setHints(result.data.hints);
    }, [functions]);

    useEffect(() => {
        const interval = setInterval(() => {
            if(nextUnreleasedHint && Date.now() > nextUnreleasedHint.releaseTime) {
                fetchHints();
            }
            setCurrentTime(Date.now());
        }, 1000);
        return () => clearInterval(interval);
    }, [fetchHints, nextUnreleasedHint]);

    useEffect(() => {
        fetchHints();
    }, [fetchHints]);

    useEffect(() => {
        (async () => {
            try {
                const data = await fetchUserData(functions);
                setUserData(data);
            } catch(e) {
                console.error(e);
                setError("Failed to fetch user data");
                return;
            }
        })();
    }, [functions]);

    const nextHintBox = useMemo(() => {
        if(!nextUnreleasedHint) return null;
        const diff = nextUnreleasedHint.releaseTime - currentTime;
        const duration = moment.duration(diff, "milliseconds");
        const days = Math.floor(duration.asDays());
        return (
            <div className={style.box}>
                <h2>Next Chameleon drops in</h2>
                {
                    days > 1 ? (
                        <h1>
                            {days} days
                        </h1>
                    ) :
                    (
                        <h1>
                            {String(duration.hours()).padStart(2, "0")}:
                            {String(duration.minutes()).padStart(2, "0")}:
                            {String(duration.seconds()).padStart(2, "0")}
                        </h1>
                    )
                }
            </div>
        );
    }, [currentTime, nextUnreleasedHint]);

    if(loading) {
        return (
            <div id={style.home_page}>
                <img src={ChameleonLogo} alt="Chameleon Logo" className="logo" />
                {error && <p id={style.error}>{error}</p>}
                <h2>Loading...</h2>
            </div>
        );
    }

    return (
        <div id={style.home_page}>
            <img src={ChameleonLogo} alt="Chameleon Logo" className="logo" />
            {error && <p id={style.error}>{error}</p>}
            <Hint hintData={latestReleasedHint} />
            {nextHintBox}
            <MailingListBox />
            <HintList hintData={releasedHints} userData={userData} />
        </div>
    )
};

export default HomePage;
