import { httpsCallable } from "firebase/functions";
import { getUID } from "./localStorage";

export async function fetchUserData(functions) {
    const uid = getUID();
    let result;
    try {
        const func = httpsCallable(functions, "getUserData");
        result = await func({ uid });
    } catch(e) {
        console.error(e);
        throw new Error("Failed to fetch user data");
    }
    if(!result.data) {
        throw new Error("Failed to fetch user data: no data returned");
    }
    return result.data;
}
