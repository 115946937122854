import React from "react";
import style from "./stylesheets/HintList.module.scss";
import ChameleonLogo from "../resources/chameleon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const HintList = ({ hintData, userData }) => {
    const hintsSorted = hintData.sort((a, b) => parseInt(b.id) - parseInt(a.id));

    const hints = hintsSorted.map(hint => {
        const foundByUser = userData && userData.foundHints.includes(hint.id);

        let paragraphText;
        if(foundByUser && hint.discovered) paragraphText = `Found by you • Discovered by ${hint.discovererDisplayName}`;
        if(foundByUser && !hint.discovered) paragraphText = "Found by you";
        if(!foundByUser && hint.discovered) paragraphText = `Not found by you • Discovered by ${hint.discovererDisplayName}`;
        if(!foundByUser && !hint.discovered) paragraphText = hint.reward === 0 ? "Undiscovered" : `Undiscovered • Reward: $${hint.reward}`;
        return (
            <Link to={`/hint/${hint.id}`} key={hint.id}>
                <div className={`${style.hint} ${foundByUser && style.found}`}>
                    <img src={ChameleonLogo} alt="Chameleon Logo" />
                    <div className={style.info_box}>
                        <h2>
                            <span className={style.number}>#{hint.id}</span>
                            {hint.discovered && hint.assignedName}
                        </h2>
                        <p>{paragraphText}</p>
                    </div>
                    <p className={style.icon}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </p>
                </div>
            </Link>
        );
    });

    return (
        <div className={style.hint_list}>
            {hints}
        </div>
    );
};

export default HintList;
