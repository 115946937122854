import AdminPage from "../components/AdminPage";
import HomePage from "../components/HomePage";
import ViewHintPage from "../components/ViewHintPage";
import DiscoveryPage from "../components/DiscoveryPage";

const ROUTES = [
    {
        path: "/",
        element: <HomePage />,
    },
    {
        path: "/hint/:id",
        element: <ViewHintPage />,
    },
    {
        path: "/admin",
        element: <AdminPage />,
    },
    {
        path: "/discover/:token",
        element: <DiscoveryPage />,
    },
];

export default ROUTES;
