import React, { useCallback, useState } from "react";
import style from "./stylesheets/AdminPage.module.scss";
import ChameleonLogo from "../resources/chameleon.png";
import Button from "./Button";
import Input from "./Input";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Link } from "react-router-dom";

const AdminPage = () => {
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [token, setToken] = useState("");
    const [tokenInput, setTokenInput] = useState("");
    const [hintInput, setHintInput] = useState("");
    const [imageInput, setImageInput] = useState("");
    const [releaseTimeInput, setReleaseTimeInput] = useState("");
    const [rewardInput, setRewardInput] = useState("0");
    const functions = getFunctions();

    const addHint = useCallback(async () => {
        setError("");
        setSuccessMessage("");
        if(!hintInput || !releaseTimeInput || !rewardInput) {
            setError("Please fill out hint, drop time, and reward fields");
            return;
        }
        let result;
        try {
            const func = httpsCallable(functions, "addHint");
            result = await func({
                hint: hintInput,
                image: imageInput,
                releaseTime: new Date(releaseTimeInput),
                reward: parseInt(rewardInput),
                adminToken: token,
            });
        } catch(e) {
            console.error(e);
            setError("Unexpected error")
            return;
        }
        if(!result.data || !result.data.success || !result.data.secretToken) {
            setError("Failed to add hint");
            console.log(result);
            return;
        }
        setSuccessMessage(`${window.location.origin}/discover/${result.data.secretToken}`);
    }, [functions, hintInput, imageInput, releaseTimeInput, rewardInput, token]);

    if(!token) return (
        <div id={style.admin_page}>
            <Link to="/">
                <img src={ChameleonLogo} alt="Chameleon Logo" className="logo" />
            </Link>
            <h1>Chameleon Administration</h1>
            <div className={style.box}>
                <Input
                    placeholder="Admin password"
                    value={tokenInput}
                    type="password"
                    onChange={(e) => setTokenInput(e.target.value)}
                />
                <Button text="Enter" onClick={() => {
                    setToken(tokenInput);
                    setTokenInput("");
                }} />
            </div>
        </div>
    );

    return (
        <div id={style.admin_page}>
            <Link to="/">
                <img src={ChameleonLogo} alt="Chameleon Logo" className="logo" />
            </Link>
            <h1>Chameleon Administration</h1>
            <div className={style.box}>
                <h2>Add Chameleon</h2>
                {error && <p id={style.error}>{error}</p>}
                <Input
                    placeholder="Hint"
                    type="textarea"
                    value={hintInput}
                    onChange={(e) => setHintInput(e.target.value)}
                />
                <Input
                    placeholder="Image URL"
                    value={imageInput}
                    onChange={(e) => setImageInput(e.target.value)}
                />
                <Input
                    placeholder="Drop time"
                    type="datetime-local"
                    value={releaseTimeInput}
                    onChange={(e) => setReleaseTimeInput(e.target.value)}
                />
                <Input
                    placeholder="Reward"
                    type="number"
                    value={rewardInput}
                    onChange={(e) => setRewardInput(e.target.value)}
                />
                <Button text="Add Chameleon" onClick={addHint} />
                {successMessage && <p id={style.success}>{successMessage}</p>}
            </div>
        </div>
    );
};

export default AdminPage;
